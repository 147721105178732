import { render, staticRenderFns } from "./BoxesStatusFilters.vue?vue&type=template&id=49b0d4f6&scoped=true&"
import script from "./BoxesStatusFilters.vue?vue&type=script&lang=ts&"
export * from "./BoxesStatusFilters.vue?vue&type=script&lang=ts&"
import style0 from "./BoxesStatusFilters.vue?vue&type=style&index=0&id=49b0d4f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49b0d4f6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VCol,VContainer,VIcon,VRadio,VRadioGroup,VRow,VSubheader})
