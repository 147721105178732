













































import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';

type Filters = {
  icon: string;
  label: string;
  value: number;
};

export default Vue.extend({
  name: 'BoxesStatusFilter',
  props: {
    filters: {
      type: Array as () => Filters[],
    },
    merchants: {
      type: Array as () => any[],
    },
    selectedMerchant: {
      type: String,
    },
  },

  computed: {
    ...mapState('boxes', ['boxesQuery']),
    selectedStatus: {
      get(): number | string {
        return this.boxesQuery.status;
      },
      set(status: number | string) {
        this.SET_BOXES_QUERIES({ ...this.boxesQuery, status, page: 1 });
      },
    },
    merchant: {
      get(): string {
        return this.selectedMerchant;
      },
      set(newValue: string) {
        this.$emit('changeMerchant', newValue);
      },
    },
  },
  methods: {
    ...mapMutations('boxes', ['SET_BOXES_QUERIES']),
  },
});
